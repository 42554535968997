$primary: #3A77BB;
$success: #9ECD4E;
$info: #40C7F2;
$danger: #E2252A;
$headBg: transparent;
$loginBorderSize: 0;
$loginBorder: none;
$speed: 30s;

@import "~font-awesome/scss/font-awesome";
@import "~bootstrap/scss/bootstrap";
@import "pe-rotating-logo";
@import "utilities";

html, body, .main-container {
  // height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}


.font-raleway {
  font-family: 'Raleway', sans-serif;
}
.font-montserrat {
  font-family: 'Montserrat', sans-serif;
}

#frontpage-slide {

}

.main-container {
  position: relative;

  .head {
    width: 100%;
    min-height: 20%;
    background: #5579aa; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #5579aa 0%, #55c9ed 26%, #bdcc94 72%, #dd9999 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #5579aa 0%,#55c9ed 26%,#bdcc94 72%,#dd9999 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #5579aa 0%,#55c9ed 26%,#bdcc94 72%,#dd9999 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    padding-bottom: 150px;
    box-sizing: content-box;
  }


  .main-content-container {
    width: 100%;
    margin-top: -150px;
  }


}

.section {
  margin-bottom: 0;
  position: relative;
}
.big-fat-preview {
  overflow: hidden;
  margin-top: 150px;
  border-radius: 20px 20px 4px 4px;
  border: 2px solid #f0f0f0;
  -webkit-box-shadow: 1px 13px 23px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 1px 13px 23px 0px rgba(0,0,0,0.25);
  box-shadow: 1px 13px 23px 0px rgba(0,0,0,0.25);
  position: relative;
  z-index: 10;
}
.big-fat-content {
  margin-top: 10px;

  .text-primary {
    color: #08293a !important;
    text-shadow: 0.5px 0.5px 1px #fff;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 20px;
  }

/*  max-height: 150px;*/
  padding: 35px;
  box-sizing: border-box;
}
.main-container {
  overflow: hidden;
}
.main-container .main-content-container {
  margin: 0;
}
.head {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0 !important;
  /*position: fixed;*/
  /*top: 0;*/
  /*left: 0;*/
}

.head-content {
  overflow: hidden;
}


.navbar {
  position: relative;
  z-index: 1000;
}



.logo {
  text-decoration: none;

  &:hover {
    text-decoration: none !important;
  }

}

.elements-container {
  float: none;
  margin-top: 0;
  left: 0;
  margin-left: 0;
  transform: scale(0.8);
}

.btn {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.navbar-toggler {
  margin-bottom: 15px;
}

.navbar-nav {
  li {
    line-height: 35px;
    margin: 0 20px;

    @include media-breakpoint-down(lg) {
      margin: 0 5px;
    }


    a {
      color: #fff;

    }
  }
}



.section {
  .animated {
    // opacity: 0;

    &.pause {
      animation: none;
    }

    @for $i from 1 to 6 {
      &.anim-delay-#{$i} {
        -webkit-animation-delay: #{$i}s;
        -moz-animation-delay: #{$i}s;
        -o-animation-delay: #{$i}s;
        animation-delay: #{$i}s;
      }
      &.anim-delay-#{$i - 1}-5 {
        -webkit-animation-delay: #{$i - 0.5}s;
        -moz-animation-delay: #{$i - 0.5}s;
        -o-animation-delay: #{$i - 0.5}s;
        animation-delay: #{$i - 0.5}s;
      }
    }

  }

  &.fp-completely, &.active {

    .animated {
      opacity: 1;
    }
  }
}



.head {

  .section-leading {
    font-size: 2.5em !important;
  }
  .section-leading {
    font-size: 1.7em !important;
  }

  @include media-breakpoint-down(md) {
    .big-fat-preview {
      margin-top: 75px;
    }
    .big-fat-content {
      padding: 0;

      .section-heading {
        font-size: 2em;
        margin-left: 20px !important;
        margin-right: 20px !important;
      }
      .section-leading {
        font-size: 1.5em !important;
        margin-left: 20px !important;
        margin-right: 20px !important;
      }
      .take-assessment {
        font-size: 1.3em;
      }
    }
  }


  @include media-breakpoint-down(sm) {

    .big-fat-content {
      .section-heading {
        font-size: 1.45em;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
      .section-leading {
        font-size: 1.3em;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
      .take-assessment {
        font-size: 0.9em;
      }
    }

  }

}

.section-1 {
  background: #3A77BB url("/images/front-page/bg-section-1.jpg") no-repeat center top;
  background-size: cover;

  .section-heading {
    display: inline-block;
    //background-color: rgba(255,255,255,0.25);
  }
  .section-leading {
    display: inline-block;
    box-sizing: border-box;
    /*background-color: #343a40 !important;*/
    background-color: rgba(0, 50, 50, 0.5) !important;;
  }

  .section-1-backdrop {
    background-color: rgba(0, 100, 100, 0.7);
  }


  @include media-breakpoint-down(xs) {
    background-position: right 15% top 50%;

    .section-heading {
      font-size: 1.5em;
    }

    .section-1-backdrop {
      background-color: rgba(0, 100, 100, 0.7);
      width: 80% !important;
    }


    .section-leading {
      font-size: 1em;
    }
  }

}


.section-2 {
  background: #3A77BB url("/images/front-page/bg-section-2.jpg") no-repeat center center;
  background-size: cover;


  .fp-tableCell {
    vertical-align: bottom;
    padding-bottom: 50px;
  }


  @include media-breakpoint-down(md) {
    background-position: right center;
    .fp-tableCell {
      vertical-align: top;
      padding-top: 50px;
    }
  }

  @include media-breakpoint-down(xs) {
    background-position: right 15% top 50%;

    .section-heading {
      font-size: 1.5em;
      margin:0 20px !important;
    }

    .section-leading {
      font-size: 1em;
      margin: 0 20px !important;
    }
  }

}

.section-3 {
  background: #5579aa; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #5579aa 0%, #55c9ed 26%, #bdcc94 72%, #dd9999 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #5579aa 0%,#55c9ed 26%,#bdcc94 72%,#dd9999 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #5579aa 0%,#55c9ed 26%,#bdcc94 72%,#dd9999 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}




#fp-nav a span {
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 0 5px #000;
  -moz-box-shadow: 0 0 0 5px #000;
  box-shadow: 0 0 1px 0 #000;
}

.home-page-logo {
  left: 50%;
  margin-left: -22.5px;
  top: 50%;
  margin-top: -35px;
  position: absolute;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  height: 60px;
  border-radius: 90%;
  box-shadow: 0px 0px 0px 2px #fff inset;
}