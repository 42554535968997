
@keyframes fire-orbit {
  0% {
    transform: rotate(0deg) translateX(-20px) rotate(0deg);
  }
  50% {
    transform: rotate(180deg) translateX(-20px) rotate(-180deg);
  }
  100% {
    transform: rotate(360deg) translateX(-20px) rotate(-360deg);
  }
}


@keyframes water-orbit {
  0% {
    transform: rotate(90deg) translateX(-20px) rotate(-90deg);
  }
  50% {
    transform: rotate(270deg) translateX(-20px) rotate(-270deg);
  }
  100% {
    transform: rotate(450deg) translateX(-20px) rotate(-450deg);
  }
}


@keyframes earth-orbit {
  0% {
    transform: rotate(180deg) translateX(-20px) rotate(180deg);
  }
  50% {
    transform: rotate(360deg) translateX(-20px) rotate(0deg);
  }
  100% {
    transform: rotate(540deg) translateX(-20px) rotate(-180deg);
  }
}


@keyframes wind-orbit {
  0% {
    transform: rotate(270deg) translateX(-20px) rotate(270deg);
  }
  50% {
    transform: rotate(450deg) translateX(-20px) rotate(90deg);
  }
  100% {
    transform: rotate(630deg) translateX(-20px) rotate(-90deg);
  }
}

.elements-container {
  position: relative;
  width: 75px;
  height: 75px;
  background: $headBg;
  box-sizing: border-box;
  text-align: center;
  border-radius: 90%;
  vertical-align: middle;
  float: left;
  margin-top: -40px;
  left: 50%;
  margin-left: -37.5px;

  .pe-element {
    left: 50%;
    margin-left: -22.5px;
    top: 30%;
    margin-top: -17.5px;
    position: absolute;
    transition: transform 0.5s;
    height: 60px;
    border-radius: 90%;
    box-shadow: 0px 0px 0px 2px #fff inset;

    &.element-fire {
      transform: translateX(-99px) translateY(0px);
    }

    &.element-water {
      transform: translateX(-33px) translateY(0px);
    }

    &.element-earth {
      transform: translateX(33px) translateY(0px);
    }

    &.element-wind {
      transform: translateX(99px) translateY(0px);
    }

  }

  &:before {
    content: '';
    width: 75px;
    height: 75px;
    display: inline-block;
    border: $loginBorder;
    border-radius: 90%;
    position: relative;
    z-index: -1;
    box-sizing: initial;
    margin: $loginBorderSize * -1;
  }

}

.elements-container.revolution {

  .pe-element {

    &.element-fire {
      animation: fire-orbit $speed linear infinite 0.25s;

      &.x2 {
        animation: fire-orbit ($speed/2) linear infinite 0.25s;
      }
      &.x3 {
        animation: fire-orbit ($speed/3) linear infinite 0.25s;
      }
      &.x4 {
        animation: fire-orbit ($speed/4) linear infinite 0.25s;
      }
      &.x5 {
        animation: fire-orbit ($speed/5) linear infinite 0.25s;
      }
      &.x10 {
        animation: fire-orbit ($speed/10) linear infinite 0.25s;
      }

    }

    &.element-water {
      animation: water-orbit $speed linear infinite 0.25s;

      &.x2 {
        animation: water-orbit ($speed/2) linear infinite 0.25s;
      }
      &.x3 {
        animation: water-orbit ($speed/3) linear infinite 0.25s;
      }
      &.x4 {
        animation: water-orbit ($speed/4) linear infinite 0.25s;
      }
      &.x5 {
        animation: water-orbit ($speed/5) linear infinite 0.25s;
      }
      &.x10 {
        animation: water-orbit ($speed/10) linear infinite 0.25s;
      }

    }

    &.element-earth {
      animation: earth-orbit $speed linear infinite 0.25s;

      &.x2 {
        animation: earth-orbit ($speed/2) linear infinite 0.25s;
      }
      &.x3 {
        animation: earth-orbit ($speed/3) linear infinite 0.25s;
      }
      &.x4 {
        animation: earth-orbit ($speed/4) linear infinite 0.25s;
      }
      &.x5 {
        animation: earth-orbit ($speed/5) linear infinite 0.25s;
      }
      &.x10 {
        animation: earth-orbit ($speed/10) linear infinite 0.25s;
      }

    }

    &.element-wind {
      animation: wind-orbit $speed linear infinite 0.25s;

      &.x2 {
        animation: wind-orbit ($speed/2) linear infinite 0.25s;
      }
      &.x3 {
        animation: wind-orbit ($speed/3) linear infinite 0.25s;
      }
      &.x4 {
        animation: wind-orbit ($speed/4) linear infinite 0.25s;
      }
      &.x5 {
        animation: wind-orbit ($speed/5) linear infinite 0.25s;
      }
      &.x10 {
        animation: wind-orbit ($speed/10) linear infinite 0.25s;
      }

    }

  }

}

